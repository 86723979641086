import { useState, useEffect } from "react";
import { Link, useLocation } from "@reach/router";
import { useTranslation } from "react-i18next";
import uniq from "lodash/uniq";
import cx from "classnames";
import isEqual from "lodash/isEqual";

import styles from "./styles.module.scss";
import { formatDate } from "../../../config/i18n";
import useQuery from "hooks/useQuery";
import Slot from "./Slot/Slot";
import Form from "../Form/Form";
import useStore from "../useStore";
import useStoreGlobal from "../../useStore";
import useSocket from "hooks/useSocket";

const DAY_IN_MS = 24 * 60 * 60 * 1000;

function Calendar(props) {
  const current = new Date();
  const location = useLocation();
  const { t } = useTranslation();
  const [lockForm, setLockForm] = useState();
  const [occupation, setOccupation] = useState({});
  const [days, setDays] = useState([]);
  const [size, setSize] = useState(0)
  const storeGlobal = useStoreGlobal()
  const socket = useSocket()
  const [news, setNews] = useState({})
  const [ready, setReady] = useState()
  const store = useStore((state) => ({
    update: state.update,
    resource: state.resource?.id,
    location: state.location?.id,
    service: state.service?.key,
    financier: state.financier?.key,
    free: !state.resource?.id ? true : !!state.free,
    isFetched: !!state.isFetched,
    date: state.date,
    refetchAt: state.refetchAt
  }));

  const startsAt = new Date(`${store.date}T00:00:00.000`)
  const filters = {}
  filters.availability = {
    startsAt: startsAt.getTime(),
    endsAt: new Date(startsAt.getTime() + size * 24 * 60 * 60 * 1000).setHours(23, 59, 59, 999),
    resource: store.resource,
    location: store.location,
    service: store.service,
    financier: store.financier,
    free: store.free
  };

  const availability = useQuery(window._l(`/availability`).substr(1), {
    body: filters.availability,
    enabled: !!store.date
  });

  const dates = [
    availability.data?.startsAt,
    availability.data?.endsAt,
    ...Object.keys(availability.data?.slots || {})
  ].filter(Boolean).map(date => new Date(date).getTime()).filter(Boolean).sort()

  filters.events = {
    cancelled: true,
    noshow: true,
    resource: store.resource,
    location: store.location,
  };

  filters.events.startsAt = dates[0]
  filters.events.endsAt = dates[dates.length - 1]

  const events = useQuery(window._l(`/events/query`).substr(1), {
    body: filters.events,
    initialData: [],
    enabled: availability.isFetched && !store.free && !!store.resource && filters.events.startsAt && filters.events.endsAt,
  });

  useEffect(() => {
    setDays([])
  }, [store.date])

  useEffect(() => {
    const callback = data => {
      if (location.pathname.includes(data.teamID)) {
        setNews(news => ({ ...news, [data.id]: data }))
        availability.refetch({ cancelRefetch: true })
      }
    }

    if (socket) {
      socket.on('events', callback)
    }

    return () => socket?.off('events', callback)
  }, [socket, location.pathname, news])

  useEffect(() => {
    if (!store.free && !events.isFetching && ready && Array.isArray(events.data)) {
      const occupation = {};
      occupation.ranges = []
      let days = {};
      const callback = event => {
        if (store.resource && event.resource.id !== store.resource) return
        if (store.location && event.location.id !== store.location && event.type !== 'lock') return
        const time = event.time || event.range
        days[event.day] = true
        occupation[event.day] ||= {};
        occupation[event.day][time] ||= [];
        const index = occupation[event.day][time].findIndex(e => e.id === event.id)
        if (index >= 0) {
          occupation[event.day][time][index] = { ...event }
        } else {
          occupation[event.day][time].push(event);
        }
        if (event.startsAt && event.endsAt && !['cancelled', 'noshow'].includes(event.status)) {
          let factor = event.type === 'lock' ? 1 : 0
          occupation.ranges.push([event.startsAt, event.endsAt + factor])
        }
      }
      events.data.forEach(callback)
      Object.values(news).forEach(callback)
      days = Object.keys(days).sort()
      setDays(current => uniq([...current, ...days]).sort().slice(0, size))
      setOccupation(occupation)
    }
  }, [events.isFetching, events.data, store.resource, news, store.free, ready]);

  useEffect(() => {
    const { slots, facets } = availability.data || {}
    if (slots) {
      const days = Object.keys(slots).sort().slice(0, size)
      setDays(days)
      store.update({ facets })
      setReady(true)
    }
  }, [availability.data]);

  useEffect(() => {
    const onResize = () => {
      let size = 1
      const iw = window.innerWidth

      if (iw < 768) {
        size = 1
      } else if (iw < 992) {
        size = 2
      } else if (iw < 1200) {
        size = 3
      } else {
        const factor = 280
        size = Math.floor((window.innerWidth - factor) / 215) - 1 || 1;
      }

      setSize(size)
    }
    window.addEventListener('resize', onResize);
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  if (!size) return null

  const width = `${100 / days.length}%`;

  return (
    <div className="bg-white">
      {events.isFetching && (
        <div
          className="position-absolute bottom-0 ms-2 mb-1"
          style={{ zIndex: 1 }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">{t("lodaing")}</span>
          </div>
        </div>
      )}
      <div>
        <div className={`d-flex justify-content-between ${styles.header}`}>
          {days.map((day) => (
            <div
              key={`header${day}`}
              style={{ width, minWidth: width, maxWidth: width }}
              className={cx(
                `px-4 d-flex flex-column align-items-center justify-content-center position-relative ${styles.col}`,
                {
                  [styles.past]: day < current.toLocaleDateString("fr-ca"),
                }
              )}
            >
              <Link
                to={window._l(`/events/agenda/${day}`)}
                data-tip={t("go_to_agenda", "Ir a la agenda")}
              >
                <div className="d-flex align-items-center">
                  <div className="me-3 fs-1 lh-1 text-dark">
                    {+day.split("-")[2]}
                  </div>
                  <div>
                    <div className="text-first-up fw-light lh-1 mb-1 fs-5 text-primary">
                      {formatDate(new Date(`${day}T00:00`), "EEEE")}
                    </div>
                    <div className="text-first-up fw-light lh-1 fs-6 text-dark opacity-50">
                      {formatDate(new Date(`${day}T00:00`), "MMMM")}
                    </div>
                  </div>
                </div>
              </Link>
              <button
                onClick={() => setLockForm(lockForm === day ? null : day)}
                data-tip={t("lock_day", "Crear un bloqueo para el dia")}
                className="btn btn-sm p-0 text-secondary lh-1 position-absolute top-0 end-0 mt-1 me-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {lockForm === day && (
                <div
                  className="text-dark position-fixed bg-light shadow p-3 rounded"
                  style={{ zIndex: 10000, top: "132px", maxWidth: '335px', minWidth: '310px' }}
                >
                  <Form
                    lockForm="day"
                    type="lock"
                    slot={{ day, time: "00:00" }}
                    onChange={() => setLockForm()}
                    onClose={() => setLockForm()}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={`${styles.days} overflow-auto`}>
          <div className={`d-flex justify-content-between`}>
            {days.map((day) => {
              const times = uniq(
                [
                  ...Object.keys(availability.data?.slots?.[day] || {}),
                  ...Object.keys(occupation[day] || {}),
                ].sort()
              );
              return (
                <div
                  key={`day${day}`}
                  style={{ width, minWidth: width, maxWidth: width }}
                  className={styles.day}
                >
                  {times.map((time) => (
                    <Slot
                      key={`slot-${day}T${time}`}
                      events={occupation[day]?.[time] || []}
                      occupation={occupation.ranges || []}
                      options={availability.data?.slots?.[day]?.[time]}
                      day={day}
                      time={time}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
