import { useEffect, useState } from "react";
import { Link } from "@reach/router";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import WaMe from "Components/WaMe/WaMe";
import useFilters from "hooks/useFilters";
import useUser from "hooks/useUser";
import useQuery from "hooks/useQuery";
import useDownload from "hooks/useDownload";
import useStorePrescription from "App/Prescription/useStore";
import styles from "./styles.module.scss";
import Switch from "../../../Components/Switch/Switch";
import Form from "../../Events/Form/Form";
import Migrate from '../Migrate/Migrate'

const Row = ({ item }) => {
  const { t } = useTranslation();
  const user = useUser();
  const prescription = useStorePrescription();
  const [locked, setLocked] = useState()

  const stats = useQuery(window._l(`/${item.id}/stats`).substr(1), {
    initialData: {
      records: 0,
      events: 0,
    },
  });

  const messages = [];
  const [showEventForm, setShowEventForm] = useState(false);

  if (item.phones.length === 0) messages.push("empty_phones");
  if (item.emails.length === 0) messages.push("empty_emails");
  if (item.financiers.length === 0) messages.push("empty_financiers");
  if (!item.identification) messages.push("empty_identification");
  if (!item.country) messages.push("empty_country");
  if (!item.gender) messages.push("empty_gender");

  for(let financier of item.financiers){
    let slug = financier.id?.split("/").filter(Boolean)[0].replace("pms_financiers:", "");
    if(/wiri|private/i.test(slug)) continue;

    if(!financier.identification || financier.identification?.length === 0){
      messages.push("invalid_financier_number");
      break;
    }

    if((slug === "osde" || slug === "osde/") && /^\d{11}$/.test(financier.identification)){
      messages.push("invalid_financier_number");
      break;
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    setLocked(user.team && user.team.locked && !user.state.isStaff)
  }, [user.team, user.state])

  return (
    <>
      <tr className="bg-white">
        <td className="p-2 px-md-3 py-md-2 px-xl-4 py-xl-3">
          <div className={`text-truncate ${item.deleted ? "text-danger" : ""}`}>
            {user.actions.can(item, "write") ? (
              <Link
                data-consumer={JSON.stringify({ id: item.id })}
                data-bs-toggle="modal"
                data-bs-target="#consumersModal"
                to={item.id.replace("consumers/", "")}
                className={item.deleted ? "link-danger" : ""}
              >
                {item.label}
              </Link>
            ) : (
              item.label
            )}
            {stats.data.events > 0 && (
              <svg
                data-tip={t(`has_event`, "Tiene turnos", {
                  count: stats.data.events,
                })}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-calendar2-date ms-2"
                viewBox="0 0 16 16"
              >
                <path d="M6.445 12.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z" />
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z" />
                <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z" />
              </svg>
            )}
            {stats.data.records > 0 && (
              <svg
                data-tip={t(`has_ehr_record`, "Tiene registros de HCE", {
                  count: stats.data.records,
                })}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="ms-2 bi bi-file-medical"
                viewBox="0 0 16 16"
              >
                <path d="M8.5 4.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L7 6l-.549.317a.5.5 0 1 0 .5.866l.549-.317V7.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L9 6l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V4.5zM5.5 9a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
              </svg>
            )}
            {item.deleted && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                width="18"
                className="text-danger ms-2"
                viewBox="0 0 20 20"
                fill="currentColor"
                data-tip={t("deleted")}
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {messages.length > 0 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                width="18"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-warning ms-2"
                data-tip={messages
                  .map(
                    (key) =>
                      `<div>${t(key, {
                        context: "consumers",
                      })}</div>`
                  )
                  .join("")}
              >
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {!!item.blocked && (
              <svg data-tip={t("consumer_blocked_event_warning")} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="text-danger ms-2" viewBox="0 0 20 20">
                <path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" />
              </svg>
            )}
          </div>
          <div className="form-text m-0">
            {!isNaN(parseInt(item.identification))
              ? parseInt(
                  item.identification.toString().replace(/\D/g, "")
                ).toLocaleString("es-AR")
              : item.identification}
          </div>
        </td>
        <td className="p-2 px-md-3 py-md-2 px-xl-4 py-xl-3 d-lg-table-cell d-none">
          {item.financiers.length > 0 && (
            <div className="d-flex">
              {item.financiers.map((financier, index) => (
                <div
                  key={`${item.id}${financier.id}`}
                  className={`${index > 0 ? "ms-4" : ""}`}
                >
                  <div>{t(financier.id, financier.label)}</div>
                  {financier.identification && (
                    <div className="form-text m-0">
                      {financier.identification}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </td>
        <td className="p-2 px-md-3 py-md-2 px-xl-4 py-xl-3 d-md-table-cell d-none">
          <div className="d-flex align-items-center">
            {item.phones.map((phone) => (
              <WaMe
                className="small me-3"
                key={`${item.id}${phone.value}`}
                phone={phone}
              />
            ))}
          </div>
          <div>
            {item.emails.map((email) => (
              <a
                className="small me-2 user-select-all"
                key={`${item.id}${email.value}`}
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:${email.value}`}
              >
                {email.value}
              </a>
            ))}
          </div>
        </td>
        <td className="p-2 px-md-3 py-md-2 px-xl-4 py-xl-3">
          <div className="d-flex justify-content-end gap-1">
            {(user.state.isStaff ||
              (user.actions.has("ehr") && user.team?.resourceID)) && (
              <>
                <Link
                  className="btn btn-sm bg-light link-primary text-end mb-1"
                  to={window._l(`/${item.id}/ehr`)}
                >
                  {t("ehr_short", "HCE", { context: "consumers" })}
                </Link>

                {user.state.country === "ar" && !locked && (
                  <button
                    className="btn btn-sm bg-light link-primary text-end mb-1"
                    onClick={() => prescription.setConsumerID(item.id)}
                  >
                    {t("prescription", "Receta")}
                  </button>
                )}
              </>
            )}
            {!locked && (
              <button
                className="btn btn-sm bg-light link-primary text-end mb-1"
                onClick={() => setShowEventForm(!showEventForm)}
              >
                {t("event")}
              </button>
            )}
          </div>
        </td>
      </tr>
      {showEventForm && (
        <tr>
          <td colSpan="4" className="p-0 bg-light">
            <div
              className={`p-4 my-3 me-3 ms-auto shadow rounded bg-white ${styles["event-form"]}`}
            >
              <Form
                onClose={() => setShowEventForm(false)}
                event={{
                  consumer: { id: item.id, label: item.label },
                  financier: {
                    key: item.financiers[0]?.id,
                    label: item.financiers[0]?.label,
                    identification: item.financiers[0]?.identification,
                  },
                }}
                consumer={item}
              />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

function Home() {
  const { t } = useTranslation();
  const download = useDownload("consumers");
  let [migrate, showMigrate] = useState(false)

  const { filters, onChange, $bottom, data, isLoading } = useFilters({
    key: "consumers",
    sort: { key: "label" },
    });
    
  const options = ["deleted", "blocked"];

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div>
      <div className="bg-white shadow-sm mb-1">
        <div className="container d-flex align-items-center py-3">
          <h6 className="m-0 me-3 d-none d-md-block">{t("consumer_plural")}</h6>
          <input
            type="search"
            className="form-control form-control-sm"
            placeholder={t("search_placholder", {
              context: "consumers",
              label: t("consumer_plural"),
            })}
            name="q"
            value={filters.q || ""}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
          <div className="flex-fill"></div>

          {download.isLoading ? (
            <div
              className="spinner-border spinner-border-sm text-primary ms-3"
              role="status"
            >
              <span className="visually-hidden">{t("loading")}</span>
            </div>
          ) : (
            <div className="btn-group btn-group-sm">
              <Link className="btn btn-outline-primary ms-3" to={"new"}>
                {t("new")}
              </Link>
              <button
                className="btn btn-sm btn-outline-primary dropdown-toggle dropdown-toggle-split"
                id="dropdownFilter"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">
                  {t("toggle_dropdown", { label: t("filter_plural") })}
                </span>
              </button>

              <div
                className="dropdown-menu dropdown-menu-end p-0"
                aria-labelledby="dropdownFilter"
                style={{ width: "280px" }}
              >
                <div className="p-3 border-bottom">
                  {options.map((key, index) => (
                    <div
                      key={`switch${key}`}
                      className={cx({ "mt-3": index !== 0 })}
                    >
                      <Switch
                        id={key}
                        onChange={() => onChange(key, !filters[key])}
                        checked={!!filters[key]}
                        hint={t(`filter_${key}_hint`, { context: "consumers" })}
                        label={t(`filter_${key}`, { context: "consumers" })}
                      />
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => showMigrate(!migrate)}
                  className="btn btn-link text-start d-block p-3 w-100 border-bottom"
                >
                  {t("import", 'Importar', { context: 'consumers' })}
                </button>
                <button
                  onClick={download.start}
                  className="btn btn-link text-start d-block p-3 w-100"
                >
                  {t("download", { label: t("consumer_plural") })}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="container">
        {migrate && (
          <Migrate className="my-3 pb-3 border-bottom" />
        )}
        <div className="shadow-sm rounded bg-white table-responsive">
          <table className={`table align-middle m-0`}>
            <tbody>
              {data.flatMap((items) =>
                items.map((item) => <Row key={item.id} item={item} />)
              )}
            </tbody>
          </table>
        </div>
        <div ref={$bottom}>
          {isLoading && (
            <div className="text-center p-3 p-lg-5">
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">{t("loading")}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Home;
