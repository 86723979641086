import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconTrash } from 'bootstrap-icons/icons/trash.svg'
import { ReactComponent as IconPlus } from 'bootstrap-icons/icons/plus.svg'

const clone = window.structuredClone || JSON.clone

function Treatment(props) {
  const { t } = useTranslation();
  let {
    product,
    label,
    presentation,
    drug,
    company,
    quantity,
    isLong,
    noSubstitute,
    generic,
    product_code,
    optional,
    added
  } = props.treatment

  if (isLong === undefined) {
    isLong = false
  }

  if (noSubstitute === undefined) {
    noSubstitute = false
  }

  if (generic === undefined) {
    generic = false
  }

  if (quantity === undefined) {
    quantity = 1
  }

  let onChange = ({ target: { name, value } }) => {
    let payload = clone(props.treatment)
    payload[name] = value
    if (props.onChange) props.onChange(payload)
  }

  return (
    <div className="border rounded p-2 mb-2">
      <div className="d-flex align-items-center justify-content-between">
        {optional && !added ? (
          <button
            className="btn btn-sm text-white bg-success me-3"
            onClick={() => onChange({ target: { name: 'add', value: true } })}
          ><IconPlus/></button>
        ) : (
          <button
            className="btn btn-sm text-secondary bg-light me-3"
            onClick={() => onChange({ target: { name: 'remove', value: true } })}
          ><IconTrash width="14"/></button>
        )}
        <div className="w-100">
          <div>
            <strong className={generic ? 'text-decoration-line-through' : '' }>{product || label || drug}</strong> {!!presentation && (
              <small>{presentation}</small>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <small className="text-secondary">{drug || t('ehr:prescriptions_form_treatment_empty_drug', 'N/A')} {!!company && (
              <sup className={generic ? 'text-decoration-line-through' : ''}>{company}</sup>
            )}</small>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2">
          {(!optional || added) && (
            <>
              <div style={{ width: '50px' }}>
                <input
                  type="number"
                  className="form-control form-control-sm"
                  onChange={onChange}
                  name="quantity"
                  min={1}
                  value={quantity}
                />
              </div>
              <div
                data-tip={t('ehr:prescription_form_isLong_tip', 'Tildar si es un tratamiento prolongado')}
                className="text-truncate btn btn-sm bg-light"
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  checked={!!isLong}
                  id={`isLong${product_code}`}
                  name="isLong"
                  onChange={() => onChange({ target: { name: 'isLong', value: !isLong } })}
                />
                <label className="form-check-label ms-1" htmlFor={`isLong${product_code}`}>
                  {t('ehr:prescription_form_isLong_short_label', 'TP')}
                </label>
              </div>
              {false && (
                <div
                  data-tip={t('ehr:prescription_form_no_substitute_tip', 'Tildar si no se debe sustituir')}
                  className="text-truncate btn btn-sm bg-light"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={!!noSubstitute}
                    id={`noSubstitute${product_code}`}
                    name="noSubstitute"
                    onChange={() => onChange({ target: { name: 'noSubstitute', value: !noSubstitute } })}
                  />
                  <label className="form-check-label ms-1" htmlFor={`noSubstitute${product_code}`}>
                    {t('ehr:prescription_form_no_substitute_short_label', 'NS')}
                  </label>
                </div>
              )}
              {product && product !== drug && (
                <div
                  data-tip={t('ehr:prescription_form_generic_tip', 'Al tildar esta opcion la receta solo con el/los principios activos y no se muestra la marca ni el laboratorio')}
                  className="text-truncate btn btn-sm bg-light"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={!!generic}
                    id={`generic${product_code}`}
                    name="generic"
                    onChange={() => onChange({ target: { name: 'generic', value: !generic } })}
                  />
                  <label className="form-check-label ms-1" htmlFor={`generic${product_code}`}>
                    {t('ehr:prescription_form_generic_short_label', 'GN')}
                  </label>
                </div>
              )}
            </>
          )}

        </div>
      </div>
    </div>
  )
}

export default Treatment;
