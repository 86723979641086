import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import helpers from "@drapp/helpers";
import isEqual from "lodash/isEqual";
import { useQueryClient, useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import useMutation from "./useMutation";
import useTeamID from "./useTeamID";
import getAccessToken from "./getAccessToken";

const { REACT_APP_API_URL } = process.env;

const can = (user, permission, payload) => {
  return true;
};

const request = (accessToken) => {
  const options = {};
  if (accessToken) {
    options.headers = { Authorization: `Bearer ${accessToken}` };
  }
  return fetch(`${REACT_APP_API_URL}/users`, options).then((res) => res.json());
};

const Hook = () => {
  const auth0 = useAuth0();
  const [state, setState] = useState({});
  const [team, setTeam] = useState();
  const teamID = useTeamID();
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();

  const accessToken = getAccessToken();

  const query = useQuery(
    ["users", accessToken.value],
    () => request(accessToken.value),
    {
      enabled: !!accessToken.value,
      refetchOnWindowFocus: false,
      placeholderData: {},
    }
  );

  const [mutate, mutation] = useMutation("users");

  const actions = {};
  actions.logout = async () => {
    try {
      window.localStorage.clear();
    } catch {}
    try {
      window.sessionStorage.clear();
    } catch {}
    try {
      await auth0.logout();
    } catch {}
  };

  actions.can = (permission, payload) =>
    state.isStaff || can(state, permission, payload);
  actions.has = (role, flag) =>
    state.isStaff || (team?.permissions?.[role] ?? flag);

  actions.onChange = (payload) => {
    const user = JSON.clone({
      ...query.data,
      ...payload,
    });

    if (user.language === "es") {
      i18n.changeLanguage("es-AR");
    } else if (user.language !== i18n.language) {
      i18n.changeLanguage(user.language || "es-AR");
    }

    queryClient.setQueryData(["users", accessToken.value], user);

    return user;
  };

  actions.save = (payload) => {
    mutate(
      JSON.clone({
        ...query.data,
        ...payload,
        verified: undefined,
      })
    );
  };

  useEffect(() => {
    if (query.data.language === "es" || i18n.language === "es") {
      i18n.changeLanguage("es-AR");
    } else if (query.data.language && query.data.language !== i18n.language) {
      i18n.changeLanguage(query.data.language || "es-AR");
    }
  }, [query.data.language, i18n.language]);

  useEffect(() => {
    if (query.data?.id && auth0.user?.sub) {
      const state = {};
      state.uid = helpers.getEmail(
        auth0.user.email || auth0.user.phone || auth0.user.sub
      );
      state.name = auth0.user?.name;
      state.email = auth0.user?.email;
      state.phone = auth0.user?.phone;
      state.verified = auth0.user?.email_verified || auth0.user?.phone_verified;
      setState(JSON.clone({ ...query.data, ...state }));
    }
  }, [JSON.stringify(query.data), JSON.stringify(auth0.user)]);

  useEffect(() => {
    if (!i18n.options.whitelist.includes(state.language) && i18n.language) {
      let [language, country] = i18n.language.split("-");
      if (country === "419") {
        country = "ar";
      } else if (!country && language === "es") {
        country = "ar";
      }
      setState({
        ...state,
        country: country?.toLowerCase() || state.country,
        language: i18n.language,
      });
    }
  }, [i18n.language, JSON.stringify(i18n.options.whitelist), JSON.stringify(state)]);

  useEffect(() => {
    const team = query.data?.teams?.find((team) => team.id === teamID);
    setTeam({ ...team });
  }, [JSON.stringify(query.data), teamID, state.uid]);

  return {
    team,
    mutation,
    query,
    state,
    actions,
  };
};

export default Hook;
